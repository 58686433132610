<template>
    <div class="action-schedule">
        <div class="title f-bold" v-html="title" />
        <div class="body" v-html="body" />
    </div>
</template>

<script>
export default {
    name: 'ActionReselect',
    props: ['message'],
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        title() {
            if (this.message.mtype === 'schedule-refin-noti') {
                return '약속잡기를 다시 시작합니다'
            } else if (this.message.mtype === 'schedule-refin-a') {
                return '재일정 선택이 완료되었어요'
            }
            return ''
        },
        body() {
            if (this.message.mtype === 'schedule-refin-noti') {
                return `${this.content.name} / ${this.$options.filters.asAge(this.content.birthday)} / ${
                    this.content.job
                } 님과의 약속잡기를 다시 시작합니다 `
            } else if (this.message.mtype === 'schedule-refin-a') {
                return `${this.content.name} / ${this.$options.filters.asAge(this.content.birthday)} / ${
                    this.content.job
                } 님과의 재일정 선택이 완료되었어요`
            }
            return ''
        },
    },
}
</script>
